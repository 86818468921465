import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 lg:grid-cols-2 gap-16 xl:gap-28 mt-3" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = {
  key: 0,
  class: "text-yellow-400"
}
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "flex justify-end items-center border-t-2 2xl:space-x-5 space-x-3 2xl:py-5 py-3 mt-10" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock($setup["Form"], {
    as: "el-form",
    "validation-schema": $setup.schema,
    onSubmit: $setup.onSubmit,
    "label-position": "top"
  }, {
    default: _withCtx(({ isSubmitting }) => [
      _createElementVNode("div", {
        class: _normalizeClass(isSubmitting && 'isSubmitting-rounded')
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode($setup["Field"], { name: "Email" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield",
                  error: errorMessage,
                  label: "Email*",
                  "model-value": value
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, { "model-value": value }), null, 16, ["model-value"])
                  ]),
                  _: 2
                }, 1032, ["error", "model-value"])
              ]),
              _: 1
            }),
            _createVNode($setup["Field"], { name: "CompanyName" }, {
              default: _withCtx(({ value, field, errorMessage, meta }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield",
                  error: errorMessage,
                  label: "Company Name*",
                  onInput: (value) => $setup.onValidateCompanyName(value, field, meta)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, { "model-value": value }), null, 16, ["model-value"]),
                    ($setup.warningMessageRef)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($setup.warningMessageRef), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["error", "onInput"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode($setup["Field"], { name: "ContactName" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "inputfield",
                    error: errorMessage,
                    label: "Person of Contact Name*"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, { "model-value": value }), null, 16, ["model-value"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              }),
              _createVNode($setup["Field"], { name: "CountryContinentId" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "inputfield",
                    error: errorMessage,
                    label: "Country*"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, _mergeProps(field, {
                        "model-value": value,
                        class: "w-full",
                        placeholder: "Select Country",
                        filterable: "",
                        onInput: (value) => $setup.remoteMethod(value.target?.value),
                        onChange: _cache[0] || (_cache[0] = ($event: any) => ($setup.remoteMethod('')))
                      }), {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.options, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.Value,
                              label: item.Name,
                              value: item.Value
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1040, ["model-value", "onInput"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", {
            class: "btn btn--green-primary",
            disabled: isSubmitting,
            submit: ""
          }, " Submit ", 8, _hoisted_7)
        ])
      ], 2)
    ]),
    _: 1
  }, 8, ["validation-schema"]))
}